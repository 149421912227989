import classNames from 'classnames/bind';
import React from 'react';

import type { ILogo } from '@components/Logo/Logo';
import { Logo } from '@components/Logo/Logo';
import { Text } from '@components/Text/Text';

import styles from './LogosRow.module.scss';
import eslLogo from '@logos/Black/esl.svg';
import avastLogo from '@logos/Colored/avast.svg';
import banijayLogo from '@logos/Colored/banijay.svg';
import rakutenLogo from '@logos/Colored/rakuten.svg';
import starzLogo from '@logos/Colored/starz.svg';
import udemyLogo from '@logos/Colored/udemy.svg';

export const mainLogos = [
  { src: starzLogo, name: 'Starz', width: 85, height: 25 },
  { src: rakutenLogo, name: 'Rakuten', width: 85, height: 27 },
  { src: udemyLogo, name: 'Udemy', width: 78, height: 29 },
  { src: eslLogo, name: 'ESL', width: 67, height: 21 },
  { src: avastLogo, name: 'Avast', width: 88, height: 28 },
  { src: banijayLogo, name: 'Banijay', width: 84, height: 24 },
];

interface IProps {
  logos?: ILogo[];
  className?: string;
  note: string;
}

const cx = classNames.bind(styles);

export const LogosRow: React.FC<IProps> = ({ logos = mainLogos, note }) => (
  <ul className={cx('wrapper', `count-${logos.length}`)}>
    <li className={styles.noteWrapper}>
      <Text
        className={styles.note}
        opacity={80}
        size={12}
        tag="strong"
        transform="uppercase"
        weight="medium"
      >
        {note}
      </Text>
    </li>
    {logos.map((logo, index) => (
      <li
        key={logo.name}
        className={styles.logoWrapper}
        style={{ animationDelay: `${index * 50}ms` }}
      >
        <Logo {...logo} />
      </li>
    ))}
  </ul>
);
